html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

:root,
::after,
::before {
  // themeable
  --deep-accent: #210234;
  --mid-accent: #3b015f;
  --bright-accent: #bea2ff;
  // global
  --deep-purple: #210234;
  --mid-purple: #3b015f;
  --bright-purple: #7200cb;
  --purple-bright: #d14cff;
  --purple-light: #bea2ff;
  --purple-tint: #796685;
  --purple-tint-50: #79668580;
  --grey-deep: #727272;
  --grey-mid: #e4e4e4;
  --grey-bright: #fafaf8;
  --white: #ffffff;

  --bg-accent: transparent;

  --funnel: #de026a;
  --funnel-accent: #fb04a8;

  --shadow-light: 0px 2px 4px 0px #0000000a;
  --shadow-heavy: -5px 4px 16px 0px #00000014;

  --interactive: #0975e0;

  --nav-primary-text: #210234;
  --nav-primary-bg: #ffffff;
  --nav-primary-button-bg: #210234;
  --nav-primary-button-bg-hover: #7200cb;
  --nav-primary-button-color: #ffffff;
  --nav-primary-button-color-hover: #ffffff;
  --nav-mob-bg: #fefcfe;

  --nav-transition: all 0.3s ease;
}

p a {
  color: var(--interactive);
}

[data-hover='false'] {
  svg:hover {
    stop:first-child {
      stop-color: #7200cb;
    }
    stop:last-child {
      stop-color: #de04a8;
    }
  }
}
