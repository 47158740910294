html body span.shimmer {
  text-align: center;
  color: rgba(255, 255, 255, 0.1);
  background: #bea2ff -webkit-gradient(linear, 100% 0, 0 0, from(#bea2ff), color-stop(0.5, #ffffff), to(#bea2ff));
  background-size: 125px 100%;
  background-clip: text;
  animation-name: shimmer;
  animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background-repeat: no-repeat;
  background-position: 0 0;
}

@keyframes shimmer {
  0% {
    background-position: -4rem top; /*50px*/
  }

  40% {
    background-position: 12.5rem top; /*200px*/
  }

  100% {
    background-position: 12.5rem top; /*200px*/
  }
}

html body span.purplePinkGradient {
  background: linear-gradient(220.11deg, rgba(255, 0, 159, 0.6) -9.93%, rgba(255, 0, 159, 0) 55.09%), #7200cb;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

html body span.pinkBrightGradient {
  background: linear-gradient(180.53deg, #d591f9 0.44%, #de026a 99.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}