.table {
  position: relative;
  overflow: auto;
  margin-bottom: 32px;
  max-width: calc(100vw - 32px);
  table {
    border-spacing: 0;
    border-collapse: collapse;
    position: relative;
    background-color: transparent;
    z-index: 0;
    tbody {
      tr {
        th {
          padding: 24px 16px;
          border-color: white;
          background: white;
          border-bottom: 2px solid #7200CB;
          text-align: left;
          div[class*='Text'] {
            p {
              font-weight: bold;
              margin-bottom: 0;
            }
          }
        }
      }
      tr {
        td {
          z-index: 1;
          border: none;
          padding: 24px 16px;
          min-width: 150px;
          div[class*='Text'] {
            p {
              margin-bottom: 0;
            }
          }
        }
      }
      tr:nth-child(even) {
        td {
          border-color: white;
          background: white;
        }
      }
      tr:nth-child(odd) {
        td {
          border-color: #E5DAFF;
          background: #E5DAFF;
        }
      }
    }
  }
  @media (max-width: 991px) {
    table {
      padding-left: 150px;
      tbody {
        tr {
          th:first-child,
          td:first-child {
            min-width: 93px;
            left: 0px;
            filter: drop-shadow(0px 10px 4px #8D8D8D);
            position: sticky;
            z-index: 3;
            overflow: visible;
            text-align: left;
          }
        }
      }
    }
  }
}