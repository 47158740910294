body {
  font-variant-ligatures: none;
}

b,
strong,
.strong {
  font-weight: 700;
}

@font-face {
  font-family: 'athena-icons';
  src: url('../public/next-assets/fonts/athena-icons.eot?8708406');
  src: url('../public/next-assets/fonts/athena-icons.woff2?77185880') format('woff2'),
    url('../public/next-assets/fonts/athena-icons.woff?77185880') format('woff'),
    url('../public/next-assets/fonts/athena-icons.ttf?77185880') format('truetype'),
    url('../public/next-assets/fonts/athena-icons.svg?77185880#athena-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: 'athena-icons';
    src: url('../public/next-assets/fonts/athena-icons.svg?8708406#athena-icons') format('svg');
  }
}

[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'athena-icons';
  font-style: normal;
  font-weight: normal;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-external:before {
  content: '\e801';
}
.icon-ratedrop:before {
  content: '\e802';
}
.icon-restart:before {
  content: '\e803';
}
.icon-arrow-r:before {
  content: '\e804';
}
.icon-automatic:before {
  content: '\e805';
}
.icon-mobile:before {
  content: '\e806';
}
.icon-login:before {
  content: '\e807';
}
.icon-smile:before {
  content: '\e808';
}
.icon-mail:before {
  content: '\e809';
}
.icon-call:before {
  content: '\e80a';
}
.icon-tick:before {
  content: '\e80b';
}
.icon-time:before {
  content: '\e80d';
}
.icon-info:before {
  content: '\e80e';
}
.icon-home:before {
  content: '\e80f';
}
.icon-bolt:before {
  content: '\e810';
}
.icon-pin:before {
  content: '\e811';
}
.icon-close:before {
  content: '\e812';
}
.icon-more:before {
  content: '\e813';
}
