@import './swiper.scss';
@import './normalize';
@import './colours';
@import './globals';
@import './variables';
@import './typography';
@import './tables';
@import './_textEffects.scss';
// ** Global styles **
*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  font-size: 16px;
}

html {
  background-color: var(--white);
  color: var(--deep-accent);
}

body {
  font-family: 'Inter', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  font-feature-settings: 'liga' on, 'clig' on, 'dlig' off;
  text-rendering: optimizeSpeed;
  touch-action: pan-y;
  overflow-x: hidden !important;
}

.page {
  background-color: var(--white);
  margin: 0 auto;
  min-height: 60vh;
  overflow: hidden;
  padding: 4rem 0 0;
  position: relative;
  z-index: 2;
}

a,
button {
  cursor: pointer;
}

// shouldn't be global.
sup {
  left: 0.25rem;
  top: -0.25rem;
}

.visually-hidden {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  img {
    image-rendering: pixelated;
  }
}
